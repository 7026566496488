import React, { FC, useState } from 'react';
import { message } from 'antd';
import { ContactUs } from './contact-us';

interface ContactUsContainerPros {
  colorWave?: string;
}

export const ContactUsContainer: FC<ContactUsContainerPros> = ({colorWave} ) => {
  const [loading, setLoading] = useState(false);

  const contactData = {
    citizen: [
      { type: 'Telefone', value: '(11) 3315-2060' },
      { type: 'Telefone', value: '(11) 3315-2031' },
    ],
    press: [
      { type: 'Telefone', value: '(11) 3315-3608' },
      { type: 'Email', value: 'contato@xvia.com.br' },
    ],
  };

  const handleSubmit = (values: {
    name: string;
    email: string;
    message: string;
  }) => {
    setLoading(true);

    setTimeout(() => {
      message.success('Mensagem enviada com sucesso!');
      setLoading(false);
    }, 1500);
  };

  return (
    <ContactUs
      loading={loading}
      onSubmit={handleSubmit}
      contactData={contactData}
      colorWave={colorWave}
    />
  );
};
