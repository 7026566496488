import React, { FC } from 'react';
import {
  DynamicNewsContainer,
  DynamicNewsTypeEnum,
} from '../modules/dynamic-news/dynamic-news.container';
import { FooterContainer } from '../modules/footer/footer.container';

import { useParams } from 'react-router-dom';

export interface DynamicNewsType {
  newsType: DynamicNewsTypeEnum;
}

export const DynamicNews: FC = () => {
  const { newsType } = useParams<DynamicNewsType>();

  return (
    <>
      <DynamicNewsContainer newsType={newsType} />
      <FooterContainer />
    </>
  );
};
