import React, { FC } from 'react';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import {
  BoxAudioPlayer,
  ContainerAudioPlayerCard,
  ContainerIconBox,
  IconButton,
  Slider,
  StyledStack,
  TinyText,
  VolumeSlider,
  Widget,
} from './style';

interface AudioPlayerProps {
  audioRef: React.RefObject<HTMLAudioElement>;
  audioSrc: string;
  handleSliderChange: (_event: Event, value: number | number[]) => void;
  position: number;
  duration: number;
  paused: boolean;
  togglePlayPause: () => void;
  formatDuration: (value: number) => string;
}

export const AudioPlayer: FC<AudioPlayerProps> = ({
  audioRef,
  audioSrc,
  handleSliderChange,
  position,
  duration,
  paused,
  togglePlayPause,
  formatDuration,
}) => {
  return (
    <ContainerAudioPlayerCard sx={{ width: '100%', overflow: 'hidden', p: 3 }}>
      <audio ref={audioRef} src={audioSrc} />
      <Widget>
        <BoxAudioPlayer>
          <TinyText mr={2.5}>{formatDuration(position)}</TinyText>
          <Slider
            aria-label="time-indicator"
            size="small"
            value={position}
            min={0}
            step={1}
            max={duration}
            onChange={(_, value) => handleSliderChange(_, value)}
          />
          <TinyText ml={2.5}>-{formatDuration(duration - position)}</TinyText>
        </BoxAudioPlayer>
        <ContainerIconBox>
          <IconButton
            aria-label={paused ? 'play' : 'pause'}
            onClick={togglePlayPause}
          >
            {paused ? (
              <PlayArrowRounded sx={{ fontSize: '3rem' }} />
            ) : (
              <PauseRounded sx={{ fontSize: '3rem' }} />
            )}
          </IconButton>
          <StyledStack>
            <VolumeDownRounded />
            <VolumeSlider
              aria-label="Volume"
              defaultValue={30}
              onChange={(_, value) => {
                if (audioRef.current) {
                  audioRef.current.volume = (value as number) / 100;
                }
              }}
            />
            <VolumeUpRounded />
          </StyledStack>
        </ContainerIconBox>
      </Widget>
    </ContainerAudioPlayerCard>
  );
};
