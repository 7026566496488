import React, { FC } from 'react';
import { VideoNews } from './video-news';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import VideocamIcon from '@mui/icons-material/Videocam';

import { ContainerBreadcrumbVideo, ContainerBreadcrumbVideoBox } from './style';

export interface VideoNewsProps {
  date?: string;
  authorRead?: string;
  authorVideo?: string;
  ContainerTextBox?: React.ReactNode;
}

export const VideoNewsContainer: FC<VideoNewsProps> = ({
  date,
  authorRead,
  authorVideo,
}) => {
  const iconMap = {
    date: DateRangeIcon,
    authorRead: NewspaperIcon,
    authorVideo: VideocamIcon,
  };

  const infoItems = [
    { label: date, Icon: iconMap.date },
    { label: authorRead, Icon: iconMap.authorRead },
    { label: authorVideo, Icon: iconMap.authorVideo },
  ].filter((item) => item.label);

  const BreadcrumbVideo = () => {
    if (infoItems.length === 0) return <></>;

    return (
      <ContainerBreadcrumbVideo>
        {infoItems.map(({ label, Icon }, index) => (
          <ContainerBreadcrumbVideoBox key={index}>
            <Icon />
            <span>{label}</span>
          </ContainerBreadcrumbVideoBox>
        ))}
      </ContainerBreadcrumbVideo>
    );
  };

  const videoNewsProps = {
    ContainerTextBox: <BreadcrumbVideo />,
  };

  return <VideoNews {...videoNewsProps} />;
};
