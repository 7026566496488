import { FC } from 'react';
import { PageBanner } from '../../components/page-banner/page-banner';
import { TitleSection } from '../../components/title-sections/title-sections';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Flag } from '@mui/icons-material';
import { AboutUsCardProps } from './about-us-card/about-us-card';
import { AboutUsCardListContainer } from './about-us-card-list/about-us-card-list.container';
import styles from './abouts-us.module.css';
import {
  AboutUsTextSection,
  AboutUsTextSectionProps,
} from './about-us-text-section/about-us-text-section';
import { Wave } from '../../components/wave/wave';

export interface AboutUsProps {
  cardList: AboutUsCardProps[];
  governors: AboutUsTextSectionProps[];
  states: AboutUsTextSectionProps[];
}

export const AboutUs: FC<AboutUsProps> = ({ cardList, governors, states }) => {
  return (
    <>
      <PageBanner
        bannerTitle="Nosso Governo"
        bannerImage={`${process.env.PUBLIC_URL}/images/banner/banner_about_us.png`}
      />
      <div className={styles.container}>
        <TitleSection
          titleSettings={{
            title: 'Eixos Estratégicos',
            colorTitle: '#202020',
            subtitle: 'Conheça os nossos Eixos Estratégicos',
            colorSubtitle: '#7B7B7B',
          }}
          iconSettings={{
            displayIcon: true,
            sectionIcon: <AccountTreeIcon />,
            iconColor: '#9E9E9E',
            iconSize: '50px',
          }}
        />
        <span className={styles.subtitle}>
          Selecione uma das principais categorias abaixo para ver as cartas de
          serviço da mesma
        </span>
        <AboutUsCardListContainer cards={cardList} />
      </div>
      <Wave waveColor="#f8f8f8" backgroundColor="#E5E7EB" />
      <div style={{ backgroundColor: '#e5e7eb' }}>
        <div className={styles.container}>
          <TitleSection
            titleSettings={{
              title: 'Sobre a X-VIA',
              colorTitle: '#202020',
              subtitle: 'Conheça um pouco da nossa empresa',
              colorSubtitle: '#7B7B7B',
            }}
            iconSettings={{
              displayIcon: true,
              sectionIcon: <Flag />,
              iconColor: '#9E9E9E',
              iconSize: '50px',
            }}
          />

          {states.map((states, index) => (
            <AboutUsTextSection
              key={index}
              title={states.title}
              text={states.text}
              image={states.image}
              imagePosition={states.imagePosition}
              backgroundColor="#E5E7EB"
            />
          ))}
        </div>
      </div>
      <Wave waveColor="#E5E7EB" backgroundColor="#f8f8f8" />
      <div className={styles.container}>
        <TitleSection
          titleSettings={{
            title: 'Nossa Liderança',
            colorTitle: '#202020',
            subtitle: 'Conheça um pouco sobre os líderes da X-VIA',
            colorSubtitle: '#7B7B7B',
          }}
          iconSettings={{
            displayIcon: true,
            sectionIcon: <AccountTreeIcon />,
            iconColor: '#9E9E9E',
            iconSize: '50px',
          }}
        />
        {governors.map((governor, index) => (
          <AboutUsTextSection
            key={index}
            title={governor.title}
            text={governor.text}
            image={governor.image}
            imagePosition={governor.imagePosition}
          />
        ))}
      </div>
    </>
  );
};
