import {
  ChevronRight,
  KeyboardDoubleArrowRight,
  SvgIconComponent,
} from '@mui/icons-material';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { theme } from '../../theme';
import { Link, LinkProps } from './link';

export interface LinkContainerProps {
  text: string;
  Icon?: SvgIconComponent;
  link: string;
  isExternal?: boolean;
}
export const LinkContainer: FC<LinkContainerProps> = ({
  text,
  Icon,
  link,
  isExternal,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();

  const calculateStyle = () => ({
    ...(Icon
      ? { marginRight: theme.spacing(1) }
      : { transform: 'translate(-9px)', marginRight: '-1.21px' }),
    ...(isHovered && { color: 'var(--xvia-color-secondary)' }),
  });

  const renderIcon = () => {
    const Component = isHovered
      ? KeyboardDoubleArrowRight
      : Icon ?? ChevronRight;
    return <Component style={calculateStyle()} />;
  };

  const props: LinkProps = {
    text,
    Icon: renderIcon(),
    link,
    isExternal,
    onClick: (e) => {
      e.preventDefault();
      if (isExternal) window.open(link, '_blank');
      else history.push(link);
    },
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
  };

  return <Link {...props} />;
};
