import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import styles from './about-us-text-section.module.css';

export interface AboutUsTextSectionProps {
  title: string;
  text: string;
  backgroundColor?: string;
  image?: string;
  imagePosition?: 'left' | 'right';
}

const renderLayout = ({
  title,
  text,
  image,
  imagePosition,
}: AboutUsTextSectionProps) => {
  if (imagePosition === 'left' && image) {
    return (
      <>
        <Grid item xs={12} md={3} className={styles.imageContainer}>
          <img src={image} alt={title} className={styles.image} />
        </Grid>
        <Grid item xs={12} md={9} className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.text}>{text}</p>
        </Grid>
      </>
    );
  }

  if (imagePosition === 'right' && image) {
    return (
      <>
        <Grid item xs={12} md={9} className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.text}>{text}</p>
        </Grid>
        <Grid item xs={12} md={3} className={styles.imageContainer}>
          <img src={image} alt={title} className={styles.image} />
        </Grid>
      </>
    );
  }

  return (
    <Grid item xs={12} className={styles.textContent}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{text}</p>
    </Grid>
  );
};

export const AboutUsTextSection: FC<AboutUsTextSectionProps> = (props) => {
  return (
    <Grid
      container
      className={styles.container}
      style={{ backgroundColor: props.backgroundColor }}
    >
      {renderLayout(props)}
    </Grid>
  );
};
