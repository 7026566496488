import React, { FC, useEffect, useRef, useState } from 'react';
import { AudioPlayer } from './audio-player';

export const AudioPlayerContainer: FC = () => {
  const audioSrc =
    'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3';

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [position, setPosition] = useState(0);
  const [duration, setDuration] = useState(0);
  const [paused, setPaused] = useState(true);

  function formatDuration(value: number) {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.floor(value % 60);
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const handleSliderChange = (_event: Event, value: number | number[]) => {
    if (!audioRef.current) return;

    const audio = audioRef.current;

    const newValue = value as number;
    audio.currentTime = newValue;
    setPosition(newValue);
  };

  const togglePlayPause = () => {
    if (!audioRef.current) return;

    const audio = audioRef.current;
    paused ? audio.play() : audio.pause();
    setPaused(!paused);
  };

  useEffect(() => {
    const audio = audioRef.current;

    if (!audio) return;

    const updateTime = () => {
      setPosition(audio.currentTime);
      setDuration(audio.duration || 0);
    };

    audio.addEventListener('timeupdate', updateTime);
    audio.addEventListener('loadedmetadata', updateTime);

    return () => {
      audio.removeEventListener('timeupdate', updateTime);
      audio.removeEventListener('loadedmetadata', updateTime);
    };
  }, []);

  const audioPlayerProps = {
    audioRef,
    audioSrc,
    handleSliderChange,
    position,
    duration,
    paused,
    togglePlayPause,
    formatDuration,
  };

  return <AudioPlayer {...audioPlayerProps} />;
};
