import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';
import MuiStack from '@mui/material/Stack';
import MuiIconButton from '@mui/material/IconButton';
import MuiTypography from '@mui/material/Typography';
import MuiSlider from '@mui/material/Slider';

export const ContainerAudioPlayerCard = styled(MuiBox)`
  background-color: #fff;
  box-shadow: #0000001c 4px 9px 15px;
  border-radius: 8px;
  margin: 0 0 20px 0;
  padding: 20px 20px 0 20px !important;
`;

export const TinyText = styled(MuiTypography)`
  font-size: '20px';
  font-weight: 400;
  letter-spacing: 0.2;
  color: #202020;
`;

export const Widget = styled(MuiBox)`
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: '100%';
  background-color: '#fff';
`;

export const FlexIcons = styled(MuiBox)`
  text-transform: capitalize;
  font-size: 18px;
`;

export const IconButton = styled(MuiIconButton)`
  margin: 0 auto;

  svg {
    color: #005ea9;
  }
`;

export const ContainerIconBox = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -8px;

  svg {
    color: #000;
  }
`;

export const StyledStack = styled(MuiStack)`
  padding-left: 8px;
  padding-right: 8px;
  min-width: 200px;
  flex-direction: row;
  align-items: center;

  & > svg {
    color: rgba(0, 0, 0, 0.4);
  }
`;

export const BoxAudioPlayer = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -16px;
`;

export const Slider = styled(MuiSlider)`
  color: #005ea9;
  height: 4px;

  & .MuiSlider-thumb {
    width: 8px;
    height: 8px;
    transition: 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);

    &::before {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
    }

    &:hover,
    &.Mui-focusVisible {
      box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.16);
    }

    &.Mui-active {
      width: 20px;
      height: 20px;
    }
  }

  & .MuiSlider-rail {
    opacity: 0.28;
  }
`;

export const VolumeSlider = styled(MuiSlider)`
  color: #005ea9;
  margin: 0 15px;

  & .MuiSlider-track {
    border: none;
  }

  & .MuiSlider-thumb {
    width: 24px;
    height: 24px;
    background-color: #fff;

    &::before {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    }

    &:hover,
    &.Mui-focusVisible,
    &.Mui-active {
      box-shadow: none;
    }
  }
`;
