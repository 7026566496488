import { FC } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { LinkContainer } from '../../modules/link/link.container';

export interface LinkListItem {
  text: string;
  Icon?: SvgIconComponent;
  link: string;
  isExternal?: boolean;
}
export interface LinkListProps {
  title: string;
  Icon: SvgIconComponent;
  linkList: LinkListItem[];
}

export const LinkList: FC<LinkListProps> = ({
  title,
  Icon: TitleIcon,
  linkList,
}) => {
  return (
    <nav
      aria-label={`Links de navegação para ${title}`}
      className="xvia-link-list"
    >
      <dl className="xvia-link-list__ul">
        <dt className="xvia-link-list__title">
          <TitleIcon />
          <span>{title}</span>
        </dt>
        {linkList.map(({ text, Icon, link, isExternal }, index) => {
          return (
            <dd key={index}>
              <LinkContainer
                text={text}
                Icon={Icon}
                link={link}
                isExternal={isExternal}
              />
            </dd>
          );
        })}
      </dl>
    </nav>
  );
};
