import React, { FC, useCallback, useEffect, useState } from 'react';
import { PodcastContent } from './podcast-content';
import { getVideoNews } from '../../../../../apis/news';
import { NewsItem } from '../all-content/all-content.container';
import { reorganizeNews } from '../../../../../util/news';
import { ROUTES } from '../../../../../routes';
import { useHistory } from 'react-router-dom';

interface PodcastContentContainerProps {
  reorganizeVideoNews?: boolean;
  directionCardColumns?: 'row' | 'column';
}

export type PodcastNewsItem = Omit<NewsItem, 'text'>;
export type PodcastNewsContent = Record<string, PodcastNewsItem[]>;

export const PodcastContentContainer: FC<PodcastContentContainerProps> = ({
  reorganizeVideoNews,
  directionCardColumns,
}) => {
  const history = useHistory();
  const [podcastNewsList, setPodcastNewsList] = useState<PodcastNewsContent>(
    {}
  );
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const redirectToNewsPodcastPage = useCallback(() => {
    const route = ROUTES.DYNAMIC_NEWS_PAGE.replace(':newsType', 'podcast');
    history.push(route);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getNewsServer = useCallback(() => {
    getVideoNews()
      .then(setPodcastNewsList)
      .catch((err) => {
        console.log(err);
      });
  }, [setPodcastNewsList]);

  useEffect(() => {
    Object.keys(podcastNewsList).length === 0 && getNewsServer();

    if (Object.keys(podcastNewsList).length > 0) {
      const total = Object.keys(podcastNewsList).length;
      setTotalPages(total);
    }
  }, [podcastNewsList, getNewsServer, setTotalPages]);

  useEffect(() => {
    if (reorganizeVideoNews) {
      const reorderItens = reorganizeNews(podcastNewsList);
      if (JSON.stringify(reorderItens) !== JSON.stringify(podcastNewsList)) {
        setPodcastNewsList(reorderItens);
      }
    }
  }, [reorganizeVideoNews, podcastNewsList]);

  return (
    <PodcastContent
      podcastProps={{
        podcastNewsList,
        page,
        totalPages,
        handleChange,
        redirectToNewsPodcastPage,
      }}
      directionCardColumns={directionCardColumns}
    />
  );
};
