import { styled } from '@mui/material';

import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  AccordionDetails
} from '@mui/material';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: '2px solid transparent',
  transition: 'all 0.3s ease',
  borderRadius: '5px',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '& .MuiAccordionSummary-content': {
    margin: '0px',
  },
  '&:hover': {
    '& .icon': {
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  '& svg': {
    color: theme.palette.primary.main,
  },
  '& .icon': {
    '& svg': {
      color: theme.palette.grey[500],
    },
  },
  '&.Mui-expanded': {
    '& svg': {
      color: theme.palette.secondary.main,
    },
    '& .icon': {
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary) `
  padding: 0px;
`

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const BorderDecoration = styled('div')(({ theme }) => ({
  borderLeft: `6px solid ${theme.palette.secondary.main}`,
  borderRadius: '10px',
  height: '62px',
  marginRight: '20px',  
}));

export const TypographyIcon = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    width: '40px',
    height: '40px'
  }
}));

export const TypographyTitle = styled(Typography)(({theme}) => ({
  fontFamily: 'Roboto Serif',
  color: theme.palette.grey[900]
}));

export const TypographySubTitle = styled(Typography)(({theme}) => ({
  color: theme.palette.grey[500]
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: '0px',
  marginTop: '16px',
}));

export const Typographychildren = styled(Typography)(({theme}) => ({
  color: theme.palette.grey[500]
}));