import { FC, useCallback } from 'react';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb/breadcrumb';
import { ArrowBack } from '@mui/icons-material';
import { Container } from '../container/container';
import { ContainerTitle, Button, ContainerPageBanner } from './style';

interface IPageBannerProps {
  bannerTitle: string;
  bannerImage?: string;
  color?: string;
  breadcrumbs?: BreadcrumbItem[];
}

export const PageBanner: FC<IPageBannerProps> = ({
  bannerTitle,
  bannerImage,
  breadcrumbs,
  color,
}) => {
  const RenderBreadcrumbs = useCallback(() => {
    if (!breadcrumbs) return <></>;

    return (
      <div className="xvia-catalog__breadcrumb_header">
        <Breadcrumb items={breadcrumbs} aria-label="breadcrumb" />
      </div>
    );
  }, [breadcrumbs]);

  return (
    <ContainerPageBanner
      style={{
        backgroundImage: `url(${bannerImage})`,
        backgroundColor: `${color}`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Container className="xvia-page-banners-container">
        <RenderBreadcrumbs />
        <ContainerTitle>
          <Button
            startIcon={<ArrowBack />}
            children=""
            className="xvia-page-banners-button"
            sx={{ padding: '0px', margin: '0px' }}
            onClick={() => window.history.back()}
          />
          <h2>{bannerTitle}</h2>
        </ContainerTitle>
      </Container>
    </ContainerPageBanner>
  );
};
