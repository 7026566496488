import { Element } from 'react-scroll';
import { AboutUsContainer } from '../modules/about-us/about-us.container';

import { FooterContainer } from '../modules/footer/footer.container';
import { FC } from 'react';

export const AboutUs: FC = () => {
  return (
    <Element name="aboutUs">
      <AboutUsContainer />
      <FooterContainer />
    </Element>
  );
};
