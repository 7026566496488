import React, { useState } from "react";
import {
  Accordion as MUIAccordion,
  AccordionProps as MUIAccordionProps,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { ExpandMore, KeyboardDoubleArrowDown } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

interface AccordionProps extends MUIAccordionProps {
  color?: "primary" | "secondary";
  title: string;
  subTitle?: string;
  icon: React.ReactNode;
  type?: "primary" | "custom";
}

export default function Accordion({
  color = "primary",
  children,
  title,
  subTitle,
  icon,
  type = "primary",
  ...rest
}: AccordionProps) {
  const theme = useTheme();

  const [hovered, setHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <MUIAccordion
      {...rest}
      sx={{
        padding: "24px",
        border: "2px solid transparent",
        transition: "all 0.3s ease",
        boxShadow: "4px 4px 8px 0 rgba(0, 0, 0, 0.04)",
        borderRadius: "5px",
        "&:hover": {
          borderColor:  type !== 'custom' ? theme.palette[color].main : null,
          backgroundColor: type !== 'custom' ? '#fff' : color === 'primary' ? theme.palette.secondary.main : theme.palette.primary.main,
          "& .label-accordion": {
            color: type === 'custom' ? '#fff' : ''
          },
          "& .label-accordion-subTitle": {
            color: type === 'custom' ? '#fff' : ''
          },
          "& svg": {
            color: type === 'custom' ? '#fff' : theme.palette[color].main,
          },
        },
        "&.Mui-expanded": {
          borderColor: theme.palette[color].main,
          backgroundColor: '#fff',
          borderWidth: "1px",
          "& svg": {
            color: 'inherit'
          },
          "& .icon": {
            "& svg": {
              color: theme.palette[color].main,
            },
          },
          "&:hover": {
            color: 'inherit',
            "& .label-accordion": {
              color: 'inherit',
            },
            "& .label-accordion-subTitle": {
              color: grey[500]
            }
          }
        },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onChange={(_event, isExpanded) => setExpanded(isExpanded)}
      aria-expanded={expanded}
    >
      <AccordionSummary
        expandIcon={
          hovered || expanded ? <KeyboardDoubleArrowDown /> : <ExpandMore />
        }
        aria-controls={`${title}-panel-content`}
        id={`${title}-panel1-header`}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Typography
            className="icon"
            sx={{
              display: "flex",
              alignItems: "center",
              "& svg": {
                width: "40px",
                height: "40px",
                color: type === "custom" ? theme.palette[color].main : grey[500],
              },
            }}
          >
            {icon}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              fontWeight={expanded ? 700 : 500}
              color={grey[900]}
              fontFamily={"'Roboto Serif', serif"}
              className="label-accordion"
            >
              {title}
            </Typography>
            <Typography
              color={grey[500]}
              className="label-accordion-subTitle"
            >
              {subTitle}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        id={`${title}-panel-content`}
        aria-labelledby={`${title}-panel1-header`}
      >
        <Typography color={grey[500]}>{children}</Typography>
      </AccordionDetails>
    </MUIAccordion>
  );
}
