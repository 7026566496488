import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';

export const ContainerContent = styled(MuiBox)(({ theme }) => ({
  margin: '50px auto 0 auto',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ContainerImageBox = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  margin: '0 0 25px 0',

  [theme.breakpoints.down('lg')]: {
    img: {
      width: '370px',
    },
  },

  [theme.breakpoints.down('md')]: {
    margin: '0 0 40px 0',
    img: {
      width: '100%',
    },
  },

  [theme.breakpoints.down(800)]: {
    flexDirection: 'column',
  },
}));

export const ContainerTextBox = styled(MuiBox)`
  display: block;
`;

export const ImageIconBox = styled(MuiBox)`
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 4px 4px 7px #00000030;
  margin: 0 15px 0 0;
  color: #bbbbbb;
`;

export const ImageTextBox = styled('span')`
  font-size: 14px;
  font-weight: 500;
  color: #9e9e9e;
`;

export const ContainerImageIconBoxExternal = styled(MuiBox)`
  display: flex;
  align-items: center;
  margin: 10px 40px 0 0;
  transition: 0.8s ease-out;
  cursor: pointer;

  &:hover {
    transition: 0.8s ease-out;

    > div {
      transition: 0.8s ease-out;
      color: #fff;
      background-color: #3a7fda;
    }

    span {
      color: #202020;
      font-weight: 800;
    }

    span > svg {
      color: #ff891b;
    }
  }
`;

export const ContainerImageIconBox = styled(MuiBox)`
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
`;

export const ImageIconChevronRight = styled('span')`
  margin: 0 0 0 auto;
  color: #bbbbbb;
  display: flex;
`;

export const VideoBox = styled('iframe')`
  width: 100%;
  min-height: 450px;
`;

export const ContainerTextBoxIcons = styled(MuiBox)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 40px 0 20px 0;
`;

export const ContainerIconBox = styled(MuiBox)`
  display: flex;

  svg {
    color: #ff891b;
    margin-right: 5px;
  }

  span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    color: #7b7b7b;
  }
`;

export const FlexIcons = styled(MuiBox)`
  text-transform: capitalize;
  font-size: 18px;
`;
