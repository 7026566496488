import React, { FC } from 'react';
import { VideoBox } from '../style';
import { VideoNewsProps } from './video-news.container';

export const VideoNews: FC<VideoNewsProps> = ({ ContainerTextBox }) => {
  return (
    <>
      {ContainerTextBox && ContainerTextBox}
      <VideoBox
        src="https://www.youtube.com/embed/EYJNl7yurgM?si=5BZQoyZBRXNFf2pQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></VideoBox>
    </>
  );
};
