import { FC } from 'react';
import {
  ContainerContent,
  ContainerIconBox,
  ContainerImageBox,
  ContainerImageIconBox,
  ContainerImageIconBoxExternal,
  ContainerTextBox,
  ContainerTextBoxIcons,
  ImageIconBox,
  ImageIconChevronRight,
  ImageTextBox,
} from './style';

import WorkIcon from '@mui/icons-material/Work';
import LanIcon from '@mui/icons-material/Lan';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ImageIcon from '@mui/icons-material/Image';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export interface IMatterNewsProps {
  origin: string;
  category: string;
  img: string;
  date: string;
  authorRead: string;
  authorPicture: string;
  newsContent: string;
}

export const MatterNews: FC<IMatterNewsProps> = ({
  origin,
  category,
  date,
  authorRead,
  authorPicture,
  newsContent,
}) => {
  return (
    <>
      <ContainerContent>
        <ContainerImageBox>
          <img
            src={`${process.env.PUBLIC_URL}/images/gov-news/news_04.png`}
            alt="Imagem da matéria"
          />

          <ContainerImageIconBoxExternal>
            <ImageIconBox>
              <WorkIcon />
            </ImageIconBox>
            <ImageTextBox>{origin}</ImageTextBox>
            <ImageIconChevronRight>
              <ChevronRightIcon />
            </ImageIconChevronRight>
          </ContainerImageIconBoxExternal>

          <ContainerImageIconBox>
            <ImageIconBox>
              <LanIcon />
            </ImageIconBox>
            <ImageTextBox>{category}</ImageTextBox>
          </ContainerImageIconBox>
        </ContainerImageBox>
        <ContainerTextBox>
          <ContainerTextBoxIcons>
            <ContainerIconBox>
              <DateRangeIcon />
              <span>{date}</span>
            </ContainerIconBox>
            <ContainerIconBox>
              <NewspaperIcon />
              <span>{authorRead}</span>
            </ContainerIconBox>
            <ContainerIconBox>
              <ImageIcon />
              <span>{authorPicture}</span>
            </ContainerIconBox>
          </ContainerTextBoxIcons>

          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: newsContent,
              }}
            ></p>
          </div>
        </ContainerTextBox>
      </ContainerContent>
    </>
  );
};
