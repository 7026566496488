import { FC, Fragment } from 'react';
import { TitleSection } from '../../../components/title-sections/title-sections';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ContainerCards, ContainerSimilarMatterNews, Grid } from './style';

import { MaterialItem } from '../../home/gov-news/tab-news-content/material-content/material-content';
import { DynamicNewsTypeEnum } from '../dynamic-news.container';

interface SimilarMatterProps {
  similarNewsList: MaterialItem[];
  newsType: DynamicNewsTypeEnum;
  CardNewsVideo: FC<ICardNewsMaterial>;
  CardNewsPodcast: FC<ICardNewsMaterial>;
  CardNewsMaterial: FC<ICardNewsMaterial>;
}

interface ICardNewsMaterial {
  item: MaterialItem;
  index: number;
}

export const SimilarDynamicNews: FC<SimilarMatterProps> = ({
  similarNewsList,
  newsType = 'material',
  CardNewsVideo,
  CardNewsPodcast,
  CardNewsMaterial,
}) => {
  return (
    <ContainerSimilarMatterNews>
      <div className="xvia-container">
        <TitleSection
          titleSettings={{
            title: 'Matérias Semelhantes',
            colorTitle: '#202020',
            subtitle: 'Leia outras matérias com conteúdo semelhante a esse',
            colorSubtitle: '#7B7B7B',
          }}
          iconSettings={{
            displayIcon: true,
            sectionIcon: <MoreHorizIcon />,
            iconColor: '#9E9E9E',
            iconSize: '40px',
          }}
        />

        <ContainerCards>
          {similarNewsList.map((item, index) => (
            <Fragment key={index}>
              <Grid item xs={12}>
                {newsType === 'video' && (
                  <CardNewsVideo item={item} index={index} />
                )}
                {newsType === 'podcast' && (
                  <CardNewsPodcast item={item} index={index} />
                )}
                {newsType === 'materia' && (
                  <CardNewsMaterial item={item} index={index} />
                )}
              </Grid>
            </Fragment>
          ))}
        </ContainerCards>
      </div>
    </ContainerSimilarMatterNews>
  );
};
