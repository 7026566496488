import React, { FC } from 'react';

import { PodcastNewsProps } from './podcast-news.container';
import { AudioPlayerContainer } from './audio-player/audio-player.container';

export const PodcastNews: FC<PodcastNewsProps> = ({ breadcrumbAudio }) => {
  return (
    <>
      {breadcrumbAudio && breadcrumbAudio}
      <AudioPlayerContainer />
    </>
  );
};
