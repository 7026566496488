import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './about-us-card.module.css';

export interface AboutUsCardProps {
  text: string;
  icon: IconProp;
  onClick: () => void;
}

export const AboutUsCard: FC<AboutUsCardProps> = ({ text, icon, onClick }) => {
  return (
    <div className={styles.card} onClick={onClick}>
      <FontAwesomeIcon icon={icon} className={styles.icon} />
      <span className={styles.title}>{text}</span>
    </div>
  );
};
