import React, { FC, useMemo } from 'react';
import { PodcastNews } from './podcast-news';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MicIcon from '@mui/icons-material/Mic';
import { ContainerBreadcrumbAudio, ContainerBreadcrumbAudioBox } from './style';

export interface PodcastNewsProps {
  date?: string;
  authorAudio?: string;
  breadcrumbAudio?: React.ReactNode;
}

export const PodcastNewsContainer: FC<PodcastNewsProps> = ({
  date,
  authorAudio,
}) => {
  const infoItems = useMemo(() => {
    const items: { icon: React.ReactNode; text: string }[] = [];

    if (date) {
      items.push({ icon: <DateRangeIcon />, text: date });
    }
    if (authorAudio) {
      items.push({ icon: <MicIcon />, text: authorAudio });
    }

    return items;
  }, [date, authorAudio]);

  const BreadcrumbAudio = () => {
    if (infoItems.length === 0) return <></>;

    return (
      <ContainerBreadcrumbAudio>
        {infoItems.map((item, index) => (
          <ContainerBreadcrumbAudioBox key={index}>
            {item.icon}
            <p>{item.text}</p>
          </ContainerBreadcrumbAudioBox>
        ))}
      </ContainerBreadcrumbAudio>
    );
  };

  const podcastNewsProps = {
    date,
    authorAudio,
    breadcrumbAudio: <BreadcrumbAudio />,
  };

  return <PodcastNews {...podcastNewsProps} />;
};
