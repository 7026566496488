import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';
import MuiCardContent from '@mui/material/CardContent';
import MuiCardActions from '@mui/material/CardActions';
import MuiCard from '@mui/material/Card';
import MuiTypography from '@mui/material/Typography';
import MuiGrid from '@mui/material/Grid';
import MuiButton from '@mui/material/Button';

export const Box = styled(MuiBox)``;

export const BoxHeaderCardIcons = styled(MuiBox)`
  display: flex;
`;

export const Grid = styled(MuiGrid)``;

export const ContainerCards = styled(MuiBox)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const ContainerCardsBigger = styled(MuiBox)`
  width: 50%;
  padding: 0 30px 0 0;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0;
    margin: 0 0 20px 0;
  }

  &:hover {
    transition: 0.5s ease-out;

    > div {
      transition: 0.5s ease-out;
      color: #fff;
      background-color: #143971;
    }

    span {
      color: #202020;
      font-weight: 800;
    }

    span > svg {
      color: #ff891b;
    }

    button {
      background-color: #ffffff;
      color: #143971;
    }
  }
`;

export const CardBigger = styled(MuiCard)`
  height: 100%;
`;

export const CardContentBigger = styled(MuiCardContent)`
  display: block;
  padding: 0;
  margin: 0;
`;

export const CardActions = styled(MuiCardActions)`
  justify-content: end;

  svg {
    margin-left: 10px;
  }
`;

export const CardContentBiggerImg = styled(MuiBox)`
  > img {
    object-fit: cover;
    width: 100%;
    height: 230px;
  }
`;

export const CardIconsInfo = styled(MuiBox)`
  display: flex;
  align-items: center;
  box-shadow: none;
  padding: 0;

  > svg {
    color: #ff891b;
    margin-right: 10px;
  }

  > p {
    text-align: left;
    font-size: 14px;
    font-family: Roboto Serif;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 120px;
    white-space: nowrap;
    margin: 0;
  }
`;

export const CardContentBiggerText = styled(MuiBox)`
  padding: 10px 20px;
`;

export const CardTitle = styled('p')`
  font-weight: 500;
  font-size: 18px;
  font-family: Roboto Serif;
  margin: 10px 0;
`;

export const CardText = styled('p')`
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto Serif;
`;

export const Typography = styled(MuiTypography)``;

export const Button = styled(MuiButton)``;

export const BoxContainerCards = styled(MuiBox)`
  width: 50%;

  @media (max-width: 1200px) {
    display: flex;
    width: 100%;
    justify-content: space-between;

    > div {
      width: 48%;
    }
  }

  @media (max-width: 767px) {
    display: block;
    width: 100%;

    > div {
      width: 100%;
    }
  }
`;

export const Card = styled(MuiCard)`
  margin: 0 0 20px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px -6px 11px rgba(0, 0, 0, 0.02);

  &:hover {
    transition: 0.5s ease-out;

    > div {
      transition: 0.5s ease-out;
      color: #fff;
      background-color: #143971;
    }

    span {
      color: #202020;
      font-weight: 800;
    }

    span > svg {
      color: #ff891b;
    }

    button {
      background-color: #ffffff;
      color: #143971;
    }
  }
`;

export const CardContent = styled(MuiCardContent)`
  display: flex;
  align-items: center;
  padding: 0 !important;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const CardContainerImg = styled(MuiBox)`
  width: 75%;

  > img {
    display: flex;
    width: 180px;
    object-fit: cover;
    height: 250px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  @media (max-width: 1200px) {
    justify-content: center;
    display: flex;
    width: 100%;
    margin: 0 0 20px 0;

    > img {
      width: 100%;
    }
  }
`;

export const CardContainerInfo = styled(MuiBox)`
  margin: 0 15px 0 12px;

  @media (max-width: 990px) {
    width: 100%;
    padding: 20px;
  }
`;

export const ActionsInCard = styled(MuiBox)`
  display: flex;
  justify-content: end;
  margin: 15px 0;
`;

export const ContainerPagination = styled(MuiBox)`
  justify-content: center;
  margin: 50px 0;
  align-items: center;
  display: flex;
`;
