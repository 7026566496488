import { FC } from 'react';
import { AboutUs } from './about-us';
import {
  faHeartbeat,
  faShieldAlt,
  faGraduationCap,
  faCity,
  faHardHat,
  faUmbrellaBeach,
  faChild,
  faBriefcase,
  faFlag,
} from '@fortawesome/free-solid-svg-icons';
import { AboutUsTextSectionProps } from './about-us-text-section/about-us-text-section';

export const AboutUsContainer: FC = () => {
  const cardList = [
    {
      text: 'Saúde',
      icon: faHeartbeat,
      onClick: () => console.log('Saúde'),
    },
    {
      text: 'Segurança Pública',
      icon: faShieldAlt,
      onClick: () => console.log('Segurança Pública'),
    },
    {
      text: 'Educação',
      icon: faGraduationCap,
      onClick: () => console.log('Educação'),
    },
    {
      text: 'Desenvolvimento Urbano',
      icon: faCity,
      onClick: () => console.log('Desenvolvimento Urbano'),
    },
    {
      text: 'Infraestrutura',
      icon: faHardHat,
      onClick: () => console.log('Infraestrutura'),
    },
    {
      text: 'Turismo',
      icon: faUmbrellaBeach,
      onClick: () => console.log('Turismo'),
    },
    {
      text: 'Primeira Infância',
      icon: faChild,
      onClick: () => console.log('Primeira Infância'),
    },
    {
      text: 'Valorização do Servidor',
      icon: faBriefcase,
      onClick: () => console.log('Valorização do Servidor'),
    },
    {
      text: 'Gestão Fiscal',
      icon: faFlag,
      onClick: () => console.log('Gestão Fiscal'),
    },
  ];

  const states: AboutUsTextSectionProps[] = [
    {
      title: 'X-VIA: Líder em Governo Digital no Brasil',
      text: `A X-VIA é uma empresa brasileira pioneira em soluções de governo digital, comprometida em transformar a relação entre cidadãos e órgãos públicos. Fundada em 2017, a X-VIA faz parte do grupo RW3 Tecnologia e traz para o Brasil uma solução inspirada na X-Road, tecnologia desenvolvida na Estônia, país referência mundial em digitalização governamental. Seu principal objetivo é integrar serviços públicos, promovendo eficiência, transparência e acessibilidade para a população.
  
      A plataforma desenvolvida pela X-VIA oferece funcionalidades como identidade digital única, interoperabilidade de dados e um portal web unificado, permitindo a troca segura de informações entre diferentes órgãos públicos. Já implementada em estados como Mato Grosso, Piauí e Amapá, a solução da X-VIA tem proporcionado melhorias significativas na prestação de serviços públicos, garantindo mais agilidade, segurança e inclusão digital para milhões de brasileiros.`,
    },
  ];

  const governors: AboutUsTextSectionProps[] = [
    {
      title: 'Roberto Florentino, CEO da X-VIA',
      text: `Roberto Florentino é o CEO da X-VIA, uma empresa referência em soluções de governo digital no Brasil. Com vasta experiência em inovação e transformação digital, Roberto lidera a implementação de plataformas tecnológicas que modernizam a administração pública, integrando serviços e aproximando cidadãos do governo. Sob sua liderança, a X-VIA tem se destacado por entregar soluções eficientes, seguras e alinhadas às necessidades do setor público brasileiro.
    
    Como CEO, Roberto Florentino impulsionou a parceria com a Estônia, país referência mundial em governo digital, adaptando a plataforma X-Road para a realidade brasileira. Sua visão estratégica e foco na excelência permitiram a implementação bem-sucedida de projetos em estados como Mato Grosso, Amapá e Piauí, promovendo transparência, eficiência e inovação nos serviços públicos. Roberto acredita que a transformação digital é essencial para criar um governo mais ágil, acessível e preparado para os desafios do futuro.`,
      image: `${process.env.PUBLIC_URL}/images/about-us/ceo-roberto-florentino.png`,
      imagePosition: 'left',
    },
    {
      title: 'Diogo Tedesco, CTO da X-VIA',
      text: `Diogo Tedesco é o CTO da X-VIA e líder técnico por trás das soluções inovadoras que têm revolucionado a digitalização do setor público brasileiro. Com uma carreira consolidada em tecnologia e engenharia de software, Diogo possui vasta experiência no desenvolvimento de sistemas escaláveis e seguros. Sua paixão por inovação e transformação digital tem sido o motor para implementar soluções tecnológicas que integram governos e cidadãos de maneira eficiente.
    
    Desde que assumiu a liderança técnica da X-VIA, Diogo coordenou a adaptação da plataforma inspirada na X-Road da Estônia, garantindo que ela atendesse às necessidades específicas do Brasil. Sob sua orientação, a X-VIA entregou projetos robustos de interoperabilidade e identidade digital em estados como Mato Grosso, Amapá e Piauí. Diogo acredita que a tecnologia é um pilar essencial para promover transparência, eficiência e qualidade nos serviços públicos, conectando o governo ao cidadão de forma ágil e acessível.`,
      image: `${process.env.PUBLIC_URL}/images/about-us/cto-diogo-tedesco.png`,
      imagePosition: 'right',
    },
    {
      title: 'Fernando Jorge dos Santos, Head de Desenvolvimento de Produto',
      text: `Fernando Jorge dos Santos atua como Head de Desenvolvimento de Produto na X-VIA, desempenhando um papel essencial na criação e evolução das soluções tecnológicas da empresa. Com vasta experiência no setor de tecnologia e inovação, Fernando lidera as iniciativas de desenvolvimento de produtos que transformam a gestão pública e aproximam o cidadão dos serviços governamentais.
    
    À frente das equipes de produto, Fernando é responsável por garantir que as soluções da X-VIA atendam às necessidades reais dos governos e da população. Sob sua liderança, a empresa tem implementado tecnologias modernas que promovem integração, segurança e eficiência, impactando positivamente milhões de brasileiros. Sua visão estratégica e foco na inovação consolidam a X-VIA como referência nacional em governo digital.`,
      image: `${process.env.PUBLIC_URL}/images/about-us/head-fernando-santos.png`,
      imagePosition: 'left',
    },
  ];

  return <AboutUs cardList={cardList} governors={governors} states={states} />;
};
