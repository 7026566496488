import { FC, JSX, MouseEventHandler } from 'react';

export interface LinkProps {
  text: string;
  Icon: JSX.Element;
  link: string;
  isExternal?: boolean;
  onClick: MouseEventHandler;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const Link: FC<LinkProps> = ({
  text,
  Icon,
  link,
  isExternal,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => (
  <a
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    href={isExternal ? link : undefined}
    target={isExternal ? '_blank' : undefined}
    rel={isExternal ? 'noreferrer' : undefined}
    aria-label={`${text} - Abre em uma nova aba`}
  >
    {Icon}
    {text}
  </a>
);
