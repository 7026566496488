import React, { FC } from 'react';

import {
  ContainerImageBox,
  ContainerImageIconBox,
  ContainerImageIconBoxExternal,
  ContainerTextBox,
  ImageIconBox,
  ImageIconChevronRight,
  ImageTextBox,
} from './style';

import WorkIcon from '@mui/icons-material/Work';
import LanIcon from '@mui/icons-material/Lan';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface DynamicNewsProps {
  origin: string;
  textContent: string;
  category: string;
}

export const DynamicNews: FC<DynamicNewsProps> = ({
  origin,
  textContent,
  category,
}) => {
  return (
    <>
      <ContainerImageBox>
        <ContainerImageIconBoxExternal>
          <ImageIconBox>
            <WorkIcon />
          </ImageIconBox>
          <ImageTextBox>{origin}</ImageTextBox>
          <ImageIconChevronRight>
            <ChevronRightIcon />
          </ImageIconChevronRight>
        </ContainerImageIconBoxExternal>

        <ContainerImageIconBox>
          <ImageIconBox>
            <LanIcon />
          </ImageIconBox>
          <ImageTextBox>{category}</ImageTextBox>
        </ContainerImageIconBox>
      </ContainerImageBox>
      <ContainerTextBox>
        <p
          dangerouslySetInnerHTML={{
            __html: textContent,
          }}
        ></p>
      </ContainerTextBox>
    </>
  );
};
