import React, { FC } from 'react';
import { Box, Grid } from '@mui/material';

import EmailIcon from '../../icons/EmailIcon';
import PhoneIcon from '../../icons/PhoneIcon';
import TalkBallonIcon from '../../icons/TalkBallonIcont';
import { DividerEncapsulated } from '../../components/divider-encapsulated/divider-encapsulated';
import ContactInfo from '../../components/contact-info/contact-info';

export interface ContactUsProps {
  loading: boolean;
  onSubmit: (values: { name: string; email: string; message: string }) => void;
  colorWave?: string;
  contactData: {
    citizen: { type: string; value: string }[];
    press: { type: string; value: string }[];
  };
}

export const ContactUs: FC<ContactUsProps> = ({
  loading,
  onSubmit,
  contactData,
  colorWave = "var(--xvia-bg-gray)"
}) => {
  return (
    <DividerEncapsulated
      dividerHeight="120px"
      dividerColor={colorWave}
    >
      <Box className="xvia-contact-us">
        <Grid container alignItems="center" spacing={2}>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              display: { xs: 'none', lg: 'block' },
            }}
          >
            <Box
              className="xvia-contact-us__image"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/contactus-couple-image.png)`,
              }}
            ></Box>
          </Grid>

          <Grid item xs={12} lg={8}>
            <Box className="xvia-contact-us__header">
              <div className="xvia-contact-us__bar"></div>
              <div className="xvia-contact-us__content">
                <TalkBallonIcon />
                <div>
                  <div className="xvia-contact-us__title">Fale conosco</div>
                  <div className="xvia-contact-us__subtitle">
                    Fale conosco por um dos nossos canais de contato oficiais
                  </div>
                </div>
              </div>
            </Box>

            <Box className="xvia-contact-us__section">
              <div className="xvia-contact-us__section-title">Cidadão</div>
              <Grid container spacing={2}>
                {contactData.citizen.map((item, index) => (
                  <ContactInfo
                    key={index}
                    icon={
                      item.type === 'Telefone' ? <PhoneIcon /> : <EmailIcon />
                    }
                    label={item.type}
                    value={item.value}
                  />
                ))}
              </Grid>
            </Box>

            <Box className="xvia-contact-us__section">
              <div className="xvia-contact-us__section-title">Imprensa</div>
              <Grid container spacing={2}>
                {contactData.press.map((item, index) => (
                  <ContactInfo
                    key={index}
                    icon={
                      item.type === 'Telefone' ? <PhoneIcon /> : <EmailIcon />
                    }
                    label={item.type}
                    value={item.value}
                  />
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </DividerEncapsulated>
  );
};
