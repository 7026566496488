import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FC, useState } from 'react';
import { Button, ButtonProps } from './button';

export interface ButtonContainerProps {
  text: string;
  color: string;
  icon: IconDefinition;
  onClick: () => void;
}
export const ButtonContainer: FC<ButtonContainerProps> = ({
  text,
  color,
  icon,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const props: Omit<ButtonProps, 'color' | 'style'> = {
    text,
    onClick,
    icon,
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
  };
  return (
    <Button
      color={isHovered ? '#fff' : color}
      style={{
        backgroundColor: isHovered ? color : '#fff',
        fontWeight: isHovered ? 700 : 500,
      }}
      {...props}
    />
  );
};
