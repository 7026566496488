import React, { FC, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Pagination } from '../../../../../components';

import TodayIcon from '@mui/icons-material/Today';
import CategoryIcon from '@mui/icons-material/Category';

import { mathIconCategory } from '../../../../../util/matchAplicationIcons';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  Box,
  BoxCardIconInfo,
  BoxChevronCard,
  BoxImage,
  BoxText,
  Card,
  CardContent,
  CardIconInfo,
  ContainerPagination,
  GridContainerCards,
  Text,
} from './style';
import { VideoNewsContent } from './video-content.container';

interface IVideoContentProps {
  directionCardColumns?: 'row' | 'column';
  videoProps: {
    newsVideoList: VideoNewsContent;
    page: number;
    totalPages: number;
    handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    redirectToNewsVideoPage: (event: React.MouseEvent<HTMLDivElement>) => void;
  };
}

export const VideoContent: FC<IVideoContentProps> = ({
  directionCardColumns,
  videoProps,
}) => {
  const {
    newsVideoList,
    page,
    totalPages,
    handleChange,
    redirectToNewsVideoPage,
  } = videoProps;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        direction={directionCardColumns ? directionCardColumns : 'row'}
        sx={{
          display: { xs: 'block', md: 'flex' },
        }}
      >
        {Object.keys(newsVideoList).length > 0 && (
          <>
            {newsVideoList[`${page}`].map((item, index) => (
              <Fragment key={index}>
                <GridContainerCards
                  onClick={redirectToNewsVideoPage}
                  item
                  xs={6}
                >
                  <Card
                    style={{
                      minHeight: '180px',
                      margin:
                        index === Object.keys(newsVideoList).length
                          ? '0'
                          : '0 0 20px 0',
                    }}
                  >
                    <CardContent>
                      <BoxImage>
                        <img src={item.imageVideo} alt={item.imageAlt} />
                      </BoxImage>
                      <BoxText>
                        <BoxCardIconInfo>
                          <CardIconInfo>
                            <TodayIcon />
                            <Typography variant="body2">{item.date}</Typography>
                          </CardIconInfo>
                          <CardIconInfo>
                            {mathIconCategory[item.category] || (
                              <CategoryIcon />
                            )}
                            <Typography variant="body2">
                              {item.category}
                            </Typography>
                          </CardIconInfo>
                        </BoxCardIconInfo>

                        <Text>{item.title}</Text>
                      </BoxText>
                      <BoxChevronCard>
                        <ChevronRightIcon />
                      </BoxChevronCard>
                    </CardContent>
                  </Card>
                </GridContainerCards>
              </Fragment>
            ))}
          </>
        )}
      </Grid>

      <ContainerPagination>
        <Pagination count={totalPages} onChange={handleChange} page={page} />
      </ContainerPagination>
    </Box>
  );
};
