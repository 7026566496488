import { GalleryNewsContent } from '../modules/home/gov-news/tab-news-content/gallery-content/gallery-content';
import { IMaterialContent, MaterialItem } from '../modules/home/gov-news/tab-news-content/material-content/material-content';
import { PodcastNewsContent } from '../modules/home/gov-news/tab-news-content/podcast-content/podcast-content.container';
import { VideoNewsContent } from '../modules/home/gov-news/tab-news-content/video-content/video-content.container';

const imageUrl = process.env.PUBLIC_URL;

export async function getNews(): Promise<IMaterialContent> {
  return {
    '1': [
      {
        title:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
        text: 'As provas da 19a edição da Olimpíada Brasileira de Matemática das Escolas Públicas (OBMEP) foram aplicadas nos 102 municípios alagoan...',
        category: 'Educação e Pesquisa',
        date: 'há 12 minutos',
        image: `${imageUrl}/images/gov-news/news_04.png`,
        imageAlt:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
      },
      {
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
    ],
    '2': [
      {
        title:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
        text: 'As provas da 19a edição da Olimpíada Brasileira de Matemática das Escolas Públicas (OBMEP) foram aplicadas nos 102 municípios alagoan...',
        category: 'Educação e Pesquisa',
        date: 'há 12 minutos',
        image: `${imageUrl}/images/gov-news/news_04.png`,
        imageAlt:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
      },
      {
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
    ],
  };
}
export async function getVideoNews(): Promise<VideoNewsContent> {
  const videoNews = {
    '1': [
      {
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        date: 'há 19 horas',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
      {
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Educação e Pesquisa',
        date: 'Meio Ambiente e Produção Rural',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
    ],
    '2': [
      {
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Educação e Pesquisa',
        date: 'Meio Ambiente e Produção Rural',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        date: 'há 19 horas',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
      {
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
      {
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
    ],
  };

  return videoNews;
}

export async function getPodcastNews(): Promise<PodcastNewsContent> {
  return {
    '1': [
      {
        title:
          'Na Expobacia Leiteira, Paulo Dantas lança programa e assina termo de fomento ao cooperativismo alagoano',
        category: 'Saúde',
        date: 'há 19 horas',
      },
      {
        title:
          'Seduc publica na próxima semana relação de servidores beneficiados com precatórios do Fundef',
        category: 'Educação e Pesquisa',
        date: 'há 2 semanas',
      },
      {
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        date: 'há 5 dias',
      },
      {
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        date: 'há 5 dias',
      },
    ],
    '2': [
      {
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Educação e Pesquisa',
        date: 'Meio Ambiente e Produção Rural',
      },
      {
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        date: 'há 19 horas',
      },
      {
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        date: 'há 5 dias',
      },
      {
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        date: 'há 5 dias',
      },
    ],
  };
}

export async function getGalleryNews(): Promise<GalleryNewsContent> {
  return {
    '1': [
      {
        title:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
        text: 'As provas da 19a edição da Olimpíada Brasileira de Matemática das Escolas Públicas (OBMEP) foram aplicadas nos 102 municípios alagoan...',
        category: 'Educação e Pesquisa',
        date: 'há 12 minutos',
        image: `${imageUrl}/images/gov-news/news_04.png`,
        imageAlt:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
      },
      {
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_04.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
    ],
    '2': [
      {
        title:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
        text: 'As provas da 19a edição da Olimpíada Brasileira de Matemática das Escolas Públicas (OBMEP) foram aplicadas nos 102 municípios alagoan...',
        category: 'Educação e Pesquisa',
        date: 'há 12 minutos',
        image: `${imageUrl}/images/gov-news/news_04.png`,
        imageAlt:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
      },
      {
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_04.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
    ],
  };
}

export async function getSimilarNews(newsType?: string): Promise<MaterialItem[]> {
  
  
  const materialNewsApiMock = [
    {
      title:
        'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      text: 'Capacitação abordou temas de urgência e ...',
      category: 'Saúde',
      date: 'há 3 horas',
      image: `${imageUrl}/images/gov-news/news_02_bigger.jpeg`,
      imageAlt:
        'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
    },
    {
      title:
        'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      text: 'Dos 16 imunizantes avaliados pelo...',
      category: 'Saúde',
      date: 'há 1 semana',
      image: `${imageUrl}/images/gov-news/news_03_bigger.jpeg`,
      imageAlt:
        'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
    },
  ]

  const videoNewsApiMock = [
    {
      title:
        'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
      text:'',
      category: 'Saúde',
      date: 'há 19 horas',
      image: `${imageUrl}/images/gov-video-news/video_01.png`,
      imageAlt: '',
    },
    {
      title:
      "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
      text:'',
      category: 'Educação e Pesquisa',
      date: 'Meio Ambiente e Produção Rural',
      image: `${imageUrl}/images/gov-video-news/video_02.png`,
      imageAlt: '',
    },
  ]
  
  if (newsType === 'video') return videoNewsApiMock;

  return materialNewsApiMock;
}
