import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';
import MuiCardContent from '@mui/material/CardContent';
import MuiCard from '@mui/material/Card';
import MuiTypography from '@mui/material/Typography';
import MuiGrid from '@mui/material/Grid';
import MuiButton from '@mui/material/Button';

export const Grid = styled(MuiGrid)`
  width: 100%;
`;

export const ContainerSimilarMatterNews = styled(MuiBox)`
  margin: 50px 0 0 0;
  background-color: #e5e7eb;

  &:before {
    content: '';
    height: 100px;
    clip-path: ellipse(55% 100% at 50% 0%);
    background-color: #f8f8f8;
    display: block;
  }

  &:after {
    content: '';
    height: 110px;
    clip-path: ellipse(60% 75% at 50% 20%);
    background-color: rgb(229 231 235);
    display: block;
    margin: 0 0 -50px 0;
  }
`;

export const ContainerCards = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const CardIconsInfo = styled(MuiBox)`
  display: flex;
  align-items: center;
  box-shadow: none;
  padding: 0;

  > svg {
    color: #ff891b;
    margin-right: 10px;
  }

  > p {
    text-align: left;
    font-size: 14px;
    font-family: Roboto Serif;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 120px;
    white-space: nowrap;
    margin: 0;
  }
`;

export const CardTitle = styled('p')`
  font-weight: 500;
  font-size: 18px;
  font-family: Roboto Serif;
  margin: 10px 0;
`;

export const CardText = styled('p')`
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto Serif;
`;

export const Typography = styled(MuiTypography)``;

export const Button = styled(MuiButton)`
  text-transform: math-auto;

  svg {
    margin: 0 0 0 10px;
  }
`;

export const Card = styled(MuiCard)`
  margin: 0 0 20px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px -6px 11px rgba(0, 0, 0, 0.02);
`;

export const CardVideo = styled(MuiCard)`
  margin: 0 0 20px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px -6px 11px rgba(0, 0, 0, 0.02);
`;

export const CardContentDynamicNews = styled(MuiCardContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 !important',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '15px !important',
  },

  '&:hover': {
    boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
    transform: 'scale(1.02)',
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    backgroundColor: '#143971',
    color: '#FFFFFF !important',

    button: {
      backgroundColor: '#FFFFFF !important',
      color: '#143971 !important',
    },
  },
}));

export const BoxImage = styled(MuiBox)(({ theme }) => ({
  display: 'block',
  margin: '0 15px 0 0',

  '> img': {
    display: 'flex',
    objectFit: 'cover',
  },

  [theme.breakpoints.down('lg')]: {
    margin: 0,
  },

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
}));

export const BoxText = styled(MuiBox)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: '10px',
  },
}));

export const BoxCardIconInfo = styled(MuiBox)(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'row',
    margin: '10px 0 0 0',
  },

  [theme.breakpoints.down(550)]: {
    flexDirection: 'column',
  },
}));

export const CardIconInfo = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: 'none',
  padding: '0',

  '> svg': {
    color: '#ff891b',
  },

  '> p': {
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: 'Roboto Serif',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: '0 10px',
    width: '110px',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '18px',
  fontFamily: 'Roboto Serif',
  margin: '10px 0',

  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
  },
}));

export const BoxChevronCard = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  margin: '0 0 0 auto',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const CardContainerImg = styled(MuiBox)(({ theme }) => ({
  width: '75%',
  '> img': {
    display: 'flex',
    width: '180px',
    objectFit: 'cover',
    height: '220px',
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    display: 'flex',
    width: '100% !important',
    margin: '0 0 20px 0',
    '> img': {
      width: '100%',
    },
  },
  [theme.breakpoints.down('lg')]: {
    width: '25%',
  },
}));

export const CardContainerInfo = styled(MuiBox)(({ theme }) => ({
  margin: '10px 15px 0 12px',
  [theme.breakpoints.down('md')]: {
    padding: '20px',
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

export const ActionsInCard = styled(MuiBox)`
  display: flex;
  justify-content: end;
  margin: 15px 0;
`;

// === Podcast Card ===
export const PodcastCard = styled(MuiCard)(({ theme }) => ({
  margin: '0 0 20px 0',
  boxShadow:
    '0px 4px 8px rgba(0, 0, 0, 0.04), 0px -6px 11px rgba(0, 0, 0, 0.02)',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    margin: '20px 0 !important',
  },
  '&:hover': {
    boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
    transform: 'scale(1.02)',
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    backgroundColor: '#143971',
    color: '#FFFFFF !important',

    '& > div > div:first-child': {
      backgroundColor: '#143971 !important',
      border: '1px solid #fff',

      '& svg': {
        color: '#FFFFFF !important',
      },
    },
  },
}));

export const PodcastCardContent = styled(MuiCardContent)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '0 !important',
  [theme.breakpoints.down('sm')]: {
    height: '100% !important',
    display: 'block',
  },
}));

export const BoxPodcastIcon = styled(MuiBox)(({ theme }) => ({
  justifyContent: 'center',
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  backgroundColor: '#7b7b7b',
  margin: '0 15px 0 0',
  color: '#fff',
  padding: '30px',
  '> svg': {
    display: 'flex',
    width: '40px',
    height: '40px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: '20px',
  },
}));
