import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import { AboutUsCard } from '../about-us-card/about-us-card';
import { AboutUsCardProps } from '../about-us-card/about-us-card';

export interface AboutUsCardListContainerProps {
  cards: AboutUsCardProps[];
}

export const AboutUsCardListContainer: FC<AboutUsCardListContainerProps> = ({
  cards,
}) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ marginTop: '24px', maxWidth: '1200px', margin: '0 auto' }}
    >
      {cards.map((card, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2.4}
          key={index}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <AboutUsCard
            text={card.text}
            icon={card.icon}
            onClick={card.onClick}
          />
        </Grid>
      ))}
    </Grid>
  );

};
