import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';

export const ContainerBreadcrumbAudio = styled(MuiBox)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 40px 0 20px 0;
`;

export const ContainerBreadcrumbAudioBox = styled(MuiBox)`
  display: flex;

  svg {
    color: #ff891b;
    margin-right: 5px;
  }

  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    color: #7b7b7b;
    margin: 0;
  }
`;
